import { countFormatted } from "./util"

const useSidebarError = ({ data }) => {
	const count = () => {
		return data?.errors?.errors?.reduce((acc, curr) => {
			return (
				acc +
				curr.errors?.reduce((prev, item) => {
					return prev + item?.errors?.length
				}, 0)
			)
		}, 0)
	}

	return {
		count: countFormatted(count()),
		errors: data?.errors?.errors,
		file: data?.file_name,
	}
}

export default useSidebarError
