import { Modal } from "@redsales/ds"

import "./ConfirmUpload.scss"

const ConfirmUpload = ({
	title = "",
	description = "",
	isVisible = false,
	onCancel = (e) => e,
	onConfirm = (e) => e,
	closeModal = (e) => e,
	titleIcon = "document",
}) => (
	<Modal
		className="confirm-upload"
		size="short"
		title={title}
		isVisible={isVisible}
		closeModal={closeModal}
		titleIcon={titleIcon}
		buttons={[
			{
				color: "gray",
				text: "Cancelar",
				variant: "ghost",
				onClick: onCancel,
			},
			{
				type: "submit",
				text: "Enviar",
				onClick: onConfirm,
				form: "kanban-sidebar-filter",
			},
		]}
	>
		<p
			className="confirm-upload__description"
			dangerouslySetInnerHTML={{ __html: description }}
		/>
	</Modal>
)

export default ConfirmUpload
