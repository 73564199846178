import './Upload.scss'
import ConfirmUpload from './components/ConfirmUpload'
import History from './components/History'
import useUpload from './hooks'
import {
	Header,
	If,
	Button,
	Input,
	EmptyState,
	Upload as UploadFile,
} from '@redsales/ds'
import Fields from 'components/Fields'
import Loader from 'components/Loader'

const Upload = () => {
	const {
		form,
		popup,
		state,
		options,
		onChange,
		onSubmit,
		canSubmit,
		refetch,
		setRefetch,
		isReloading,
		inputFilterValue,
		onFilterByName,
		handleChangeBaseType,
		handleChangeBase,
	} = useUpload({})
	/**
	 * TODO:REMOVE THIS FLAG WHEN THIS FEATURE BE DEVELOPED.
	 */
	const hasDownloadTemplate = false

	return (
		<>
			<section className="upload">
				<Header
					title="Upload"
					topContent={
						<div className="upload__header upload__header--top">
							<Input.Default
								type="search"
								className="access-management__search"
								placeholder="Buscar"
								rightIcon="search"
								onChange={(_, value) => onFilterByName(value)}
							/>
							<If condition={hasDownloadTemplate}>
								<Button
									variant="ghost"
									rightIcon={null}
									leftIcon="download-cloud"
									className="upload__template"
									dropdown={options?.downloadTemplate}
								>
									Download template
								</Button>
							</If>
						</div>
					}
				/>
				<div className="upload__file">
					<If
						condition={isReloading}
						renderIf={
							<EmptyState
								icon=""
								ImageComponent={<Loader />}
								title="Finalizando o Upload"
							/>
						}
						renderElse={
							<UploadFile
								form={form}
								name="upload-file"
								file={state.file}
								onChange={onChange}
								onSubmit={onSubmit}
								errors={state.errors}
								isLoading={state.loading}
								buttonDisabled={!canSubmit}
								status={state.uploadStatus}
							>
								<div className="upload__filters">
									<Fields.Select.Search
										hasToFollowOutside
										name="base-type"
										control={form.control}
										placeholder="Tipo de base"
										options={options?.typeOptions}
										className="upload__filters--product"
										defaultValue={state.defaultBusinessId}
										onChange={handleChangeBaseType}
									/>

									<Fields.Select.Search
										hasToFollowOutside
										name="base"
										control={form.control}
										placeholder="Base"
										options={options?.baseOptions}
										className="upload__filters--product"
										defaultValue={state.defaultBusinessId}
										onChange={handleChangeBase}
									/>
								</div>
							</UploadFile>
						}
					/>
				</div>
				<History
					refetch={refetch}
					setRefetch={setRefetch}
					filter={inputFilterValue}
				/>
			</section>

			<ConfirmUpload
				{...state.confirm}
				isVisible={!!state.confirm}
				closeModal={popup.cancel}
				onCancel={popup.cancel}
				onConfirm={popup.confirm}
				titleIcon="clipboard-copy"
			/>
		</>
	)
}

export default Upload
