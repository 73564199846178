export const tableData = {
	info: {
		0: {
			key: 'file_name',
			name: 'file_name',
			label: 'Nome do arquivo',
			is_sortable: true,
			type: 'DEFAULT',
			primary: true,
			hidden: false,
			order: 1,
			text: 'Nome do arquivo',
			scroll_lock: 'left',
		},
		1: {
			key: 'base.name',
			name: 'base.name',
			label: 'Tipo de base',
			is_sortable: true,
			type: 'DEFAULT',
			primary: false,
			hidden: false,
			order: 2,
			text: 'Tipo de base',
			scroll_lock: '',
		},
		2: {
			key: 'time',
			name: 'time',
			label: 'Data de Upload',
			is_sortable: true,
			type: 'DEFAULT',
			primary: false,
			hidden: false,
			order: 3,
			text: 'Data de Upload',
			scroll_lock: '',
		},
		3: {
			key: 'user_name',
			name: 'user_name',
			label: 'Usuário',
			is_sortable: true,
			type: 'DEFAULT',
			primary: false,
			hidden: false,
			order: 4,
			text: 'Usuário',
			scroll_lock: '',
		},
	},
	infos: {
		0: {
			key: 'file_name',
			name: 'file_name',
			label: 'Nome do arquivo',
			is_sortable: true,
			type: 'DEFAULT',
			primary: true,
			hidden: false,
			order: 1,
			text: 'Nome do arquivo',
			scroll_lock: 'left',
		},
		1: {
			key: 'base.name',
			name: 'base.name',
			label: 'Tipo de base',
			is_sortable: true,
			type: 'DEFAULT',
			primary: false,
			hidden: false,
			order: 2,
			text: 'Tipo de base',
			scroll_lock: '',
		},
		2: {
			key: 'time',
			name: 'time',
			label: 'Data de upload',
			is_sortable: true,
			type: 'DEFAULT',
			primary: false,
			hidden: false,
			order: 3,
			text: 'Data de upload',
			scroll_lock: '',
		},
		3: {
			key: 'user_name',
			name: 'user_name',
			label: 'Usuário',
			is_sortable: true,
			type: 'DEFAULT',
			primary: false,
			hidden: false,
			order: 4,
			text: 'Usuário',
			scroll_lock: '',
		},
	},
	order: { created_at: 'desc' },
	columnQuantity: 4,
}
