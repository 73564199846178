import { tableData } from './mock'
import { useParameters } from 'hooks/useParameters'
import { HIDDEN_MODAL_INFO, MODAL_STATE } from 'pages/Upload/constants'
import { useCallback, useEffect, useRef, useState } from 'react'
import services from 'services'

const useHistory = ({ refetch, setRefetch, filter }) => {
	const {
		page: [currentPage],
	} = useParameters('page')
	const [pagination, setPagination] = useState({ total_pages: 1, page: 1 })
	const [header, setHeader] = useState({})
	const [filesList, setFilesList] = useState([])
	const [modal, setModal] = useState(HIDDEN_MODAL_INFO)

	const queries = useRef({})

	const setQueries = (values) => {
		queries.current = { ...queries.current, ...values }
	}

	const handleFetchTable = useCallback((data) => {
		if (data?.columnQuantity > 0) {
			setHeader(data)
			return
		}
		setHeader({})
	}, [])

	const fetchHistory = useCallback(
		(props) => {
			setQueries(props)

			services.bases.uploadHistory({}).then(handleFetchData)

			handleFetchTable(tableData)
		},
		[currentPage]
	)

	const openModal = (data) => {
		if (!!data?.errors) {
			setModal({ state: MODAL_STATE.VISIBLE, data: data })
			return
		}
		setModal(HIDDEN_MODAL_INFO)
	}

	const closeModal = () => {
		setModal(HIDDEN_MODAL_INFO)
	}

	const handleFetchData = useCallback(({ data, meta }) => {
		setFilesList(data)
		setPagination(Object(meta))
		setRefetch(false)
	}, [])

	useEffect(() => {
		fetchHistory(filter)
	}, [fetchHistory, refetch, filter])

	return {
		header: {
			data: header,
			info: Object.values(header.infos || {}),
		},
		openModal,
		pagination,
		files: filesList,
		onClose: closeModal,
		refresh: fetchHistory,
		sidebarData: modal?.data,
		isModalVisible: modal?.state === MODAL_STATE.VISIBLE,
	}
}
export default useHistory
