import { UPLOAD_DICTIONARY } from './constants'

export const INITIAL_STATE = {
	file: false,
	file_same_name: false,
	type: null,
	errors: [],
	success: false,
	cancel: false,
	loading: false,
	confirm: false,
	submited: false,
	newUpload: false,
	uploadStatus: UPLOAD_DICTIONARY.RECEIVE_FILE,
}

export const Reducer = (state, action) => {
	switch (action.type) {
		case 'SUBMIT_FORM':
			return {
				...state,
				loading: true,
				confirm: null,
				submited: true,
				uploadStatus: UPLOAD_DICTIONARY.RECEIVE_FILE,
			}
		case 'RECEIVE_FILE':
			return {
				...state,
				uploadStatus: UPLOAD_DICTIONARY.RECEIVE_FILE,
			}
		case 'CONFIRM_UPLOAD_CANCEL':
			return {
				...state,
				confirm: null,
				uploadStatus: UPLOAD_DICTIONARY.RECEIVE_FILE,
			}
		case 'CONFIRM_CANCELED':
			return {
				...state,
				confirm: null,
			}
		case 'CONFIRM_UPLOAD_SUCCESS':
			return {
				...INITIAL_STATE,
				type: state.type,
				confirm: {
					eventType: 'SUBMIT_FORM',
					titleIcon: 'document',
					title: 'Enviar Upload',
					description: `
					Tem certeza que deseja enviar <b>${action.payload.name}</b>? <br/> Essa ação não pode ser desfeita. <br><br>

					<b>Base atual:</b>
					${action.payload.previous_number_of_lines} linhas
					<br>
					<b>Seu arquivo:</b>
					${action.payload.current_number_of_lines} linhas 

					`,
				},
			}
		case 'UPLOADING_FILE':
			return {
				...INITIAL_STATE,
				loading: true,
			}
		case 'CONFIRM_DUPLICATE_UPLOAD':
			return {
				...INITIAL_STATE,
				loading: false,
				file: state.file,
				file_same_name: true,
				type: state.type,
				confirm: {
					eventType: 'CONFIRM_DUPLICATE_UPLOAD',
					title: 'Arquivo duplicado',
					titleIcon: 'document',
					description: action.payload.message,
				},
				uploadStatus: UPLOAD_DICTIONARY.RECEIVE_FILE,
			}
		case 'SUCCESS':
			return {
				...INITIAL_STATE,
				newUpload: true,
				submited: true,
				success: {
					title: 'Upload em andamento!',
					description: 'Acompanhe seus arquivos no histórico.',
				},
				uploadStatus: UPLOAD_DICTIONARY.RECEIVE_FILE,
			}
		case 'ERRORS':
			return {
				...INITIAL_STATE,
				submited: true,
				loading: false,
				newUpload: true,
				file: state.file,
				type: state.type,
				errors: action.payload.errors,
				uploadStatus: UPLOAD_DICTIONARY.ERROR,
			}
		case 'CANCEL':
			return {
				...state,
				loading: false,
				newUpload: true,
				cancel: {
					title: 'Upload cancelado!',
					description:
						action.payload?.message ||
						'Houve um erro ao carregar seu arquivo, tente novamente mais tarde!',
				},
				uploadStatus: UPLOAD_DICTIONARY.RECEIVE_FILE,
			}
		case 'NEW_UPLOAD': {
			return {
				...INITIAL_STATE,
			}
		}
		default:
			return state
	}
}
