import { Button, Sidebar } from "@redsales/ds"

import useSidebarError from "./hooks"

import "./SidebarErrorUpload.scss"

const ErrorInfo = ({ line, columns }) => (
	<div key={line} className="sidebar-error-upload__error--container">
		<p className="sidebar-error-upload__error--line">{line}</p>
		{columns?.map(({ message: column, errors: messages }) => (
			<ul key={column} className="sidebar-error-upload__error--list">
				<li className="sidebar-error-upload__error--column">{column}</li>
				<div className="sidebar-error-upload__detail">
					<div className="sidebar-error-upload__detail--line"></div>
					<div className="sidebar-error-upload__detail--circle"></div>
				</div>
				{messages?.map(({ message }) => (
					<li key={message} className="sidebar-error-upload__error--message">
						{message}
					</li>
				))}
			</ul>
		))}
	</div>
)

const SidebarErrorUpload = ({
	data = {},
	isVisible = false,
	onClose = () => {},
}) => {
	const { errors, file, count } = useSidebarError({
		data,
	})

	return (
		<Sidebar
			size="short"
			titleIcon="warning"
			isVisible={isVisible}
			closeSidebar={() => onClose()}
			title="Erro no upload"
			className="sidebar-error-upload"
		>
			<p className="sidebar-error-upload__message">
				Encontramos
				{count} nesta base nas linhas abaixo. Corrija as linhas abaixo e tente
				novamente.
			</p>
			<div className="sidebar-error-upload__file-name--container">
				<label className="sidebar-error-upload__file-name--label">
					Nome do arquivo
				</label>
				<p className="sidebar-error-upload__file-name">{file}</p>
			</div>

			{errors?.map(({ message: line, errors: columns }) => (
				<ErrorInfo line={line} columns={columns} />
			))}

			<footer className="sidebar-error-upload__footer">
				<Button
					color="gray"
					variant="ghost"
					size="small"
					onClick={() => onClose()}
					className="sidebar-error-upload__button"
				>
					Fechar
				</Button>
			</footer>
		</Sidebar>
	)
}

export default SidebarErrorUpload
