/* eslint-disable import/no-anonymous-default-export */
import { Fields } from '@redsales/ds'

export default {
	Input: {
		DateTime: Fields.Input.Datetime,
		...Fields.Input,
		Text: Fields.Input.Text,
		Phone: Fields.Input.Phone,
		Radio: Fields.Input.Radio,
		Password: Fields.Input.Password,
		Document: Fields.Input.Document,
		Currency: Fields.Input.Currency,
	},
	Select: {
		...Fields.Select,
		Search: Fields.Select.Search,
		Default: Fields.Select.Default,
	},
	Checkbox: {
		...Fields.Checkbox,
		Default: Fields.Checkbox.Default,
		Multiple: Fields.Checkbox.Multiple,
	},
}
