import useUploadForm, { formValues } from './form'
import { Reducer, INITIAL_STATE } from './reducer'
import { getIfCanSubmit, getBusinessTemplate } from './utils'
import { _array, _message, _helpers, _browser } from '@redsales/ds/utils'
import { useEffect, useReducer, useRef, useState } from 'react'
import services from 'services'
import { uploadToS3 } from 'utils/aws'

const useUpload = () => {
	const form = useUploadForm()

	const businessId = form.watch('base-type')

	const fileRef = useRef([])

	const [typeOptions, setTypeOptions] = useState([])
	const [baseOptions, setBaseOptions] = useState([])
	const [selectedBaseType, setSelectedBaseType] = useState(null)
	const [selectedBase, setSelectedBase] = useState(null)
	const [refetch, setRefetch] = useState(false)
	const [isReloading, setIsReloading] = useState(false)
	const [inputFilterValue, setInputFilterValue] = useState('')
	const [state, dispatch] = useReducer(Reducer, INITIAL_STATE)

	const fileData = useRef({})

	const cancelPopUp = () => dispatch({ type: 'CONFIRM_CANCELED' })

	const confirmPopUp = () => {
		cancelPopUp()
		const [file] = fileRef.current
		const base = { baseTypeId: selectedBaseType, baseId: selectedBase }

		services.bases
			.confirmUpload({ ...base, fileId: fileData.current.file_id })
			.then(() => {
				onUploadWithSuccess()
			})

		return onConfirmUpload(file)
	}

	const onFilterByName = _browser.debounce((value) => {
		const filterData = _helpers.isSearchable(value)
			? { search: value, page: '1' }
			: { search: '' }
		setInputFilterValue(filterData)
	}, 1000)

	const onChange = (name, value) => {
		fileRef.current = value?.files

		if (value.hasResetted)
			return dispatch({
				type: 'NEW_UPLOAD',
			})

		if (_array.hasAtLeast(1, fileRef.current)) {
			return dispatch({ type: 'RECEIVE_FILE' })
		}
	}

	const onSubmit = async () => {
		const [file] = fileRef.current

		const base = { baseTypeId: selectedBaseType, baseId: selectedBase }

		services.bases
			.upload({
				...base,
				file,
			})
			.then((data) => {
				handleUploadBase(data, file, base)
			})
	}

	const onNewUpload = () => {
		dispatch({ type: 'NEW_UPLOAD' })
	}

	const onDownloadTemplate = (baseTypeId) => {
		// ! Bug: [BACKEND] API precisa ter um template cadastrado para testar
		services.baseTypes
			.getTemplate({ baseTypeId })
			.then((response) => console.log('onDownloadTemplate', response))
	}

	const onCancelUpload = () => {
		dispatch({ type: 'CONFIRM_UPLOAD_CANCEL' })
	}

	const onConfirmUpload = () => {
		onUploadWithSuccess()
	}

	const uploadToAws = (credentials, file, base) => {
		uploadToS3(credentials, file, ({ loaded, total }) => {
			if (loaded !== total) {
				dispatch({ type: 'UPLOADING_FILE' })
			}
		})
			.then(({ fileId }) => {
				return services.bases.finishUpload({ ...base, fileId })
			})
			.then(({ data }) => {
				dispatch({ type: 'CONFIRM_UPLOAD_SUCCESS', payload: data })
			})
			.catch(({ data: { message, error } }) => {
				if (error) {
					return dispatch({ type: 'ERRORS', payload: { errors: message } })
				}
			})
	}

	const handleChangeBaseType = (_, { value: baseTypeId }) => {
		setSelectedBaseType(baseTypeId)
		getBaseOptions(baseTypeId)
		onDownloadTemplate(baseTypeId)
	}
	const handleChangeBase = (_, { value: baseId }) => {
		setSelectedBase(baseId)
	}

	const getBaseOptions = (baseTypeId) => {
		services.options.baseOptions({ baseTypeId }).then((baseOptions) => {
			setBaseOptions(baseOptions)
		})
	}

	useEffect(() => {
		services.options.baseTypes().then((typeOptions) => {
			setTypeOptions(typeOptions)
		})
	}, [])

	const onUploadWithSuccess = () => {
		form.reset(formValues)
		dispatch({ type: 'SUCCESS' })
		setRefetch(true)
		setIsReloading(true)
	}

	const handleUploadBase = (data, file, base) => {
		fileData.current = data
		uploadToAws(data, file, base)

		const { error, code, message, errors } = data

		switch (true) {
			case code === 'warning':
				return dispatch({
					type: 'CONFIRM_DUPLICATE_UPLOAD',
					payload: { message },
				})
			case Array.isArray(errors):
				return dispatch({ type: 'ERRORS', payload: { errors } })
			case error:
				return dispatch({ type: 'CANCEL', payload: { message } })
			default:
				return
		}
	}

	useEffect(() => {
		if (!!isReloading) {
			_helpers.wait(500).then(() => setIsReloading(false))
		}
	}, [isReloading])

	useEffect(() => {
		if (!state.success) return

		_message.showMessage({
			...state.success,
			variant: _message.variant.SUCCESS,
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state.success])

	useEffect(() => {
		if (!state.cancel) return

		_message.showMessage({
			...state.cancel,
			variant: _message.variant.ERROR,
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state.cancel])

	useEffect(() => {
		if (state.errors.length === 0) return

		_message.showMessage({
			title: state.errors,
			variant: _message.variant.ERROR,
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state.errors])

	return {
		form,
		state,
		onChange,
		onSubmit,
		isReloading,
		onNewUpload,
		onCancelUpload,
		onConfirmUpload,
		options: {
			typeOptions,
			baseOptions,
			downloadTemplate: getBusinessTemplate(),
		},
		canSubmit: getIfCanSubmit({
			files: fileRef.current,
			businessId: businessId,
		}),
		popup: {
			cancel: cancelPopUp,
			confirm: confirmPopUp,
		},
		refetch,
		setRefetch,
		onFilterByName,
		inputFilterValue,
		handleChangeBaseType,
		handleChangeBase,
	}
}

export default useUpload
