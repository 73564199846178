import AWS from 'aws-sdk'

export const uploadToS3 = (credentials, fileData, onUploadProgress) => {
	AWS.config.update({
		region: credentials.region,
		credentials: {
			accessKeyId: credentials.access_key,
			secretAccessKey: credentials.secret_key,
			sessionToken: credentials.session_token,
		},
	})

	const upload = new AWS.S3.ManagedUpload({
		params: {
			Bucket: credentials.bucket,
			Key: credentials.file,
			Body: fileData,
		},
	})

	upload.on('httpUploadProgress', function (evt) {
		onUploadProgress(evt)
	})

	const result = upload.promise()

	return result.then((data) => ({ ...data, fileId: credentials.file_id }))
}
