import { _array } from '@redsales/ds/utils'

export const fromBusinessToOption = (
	business,
	onClick = (business_id) => business_id
) => {
	return !!business
		? business?.map((item, i) => {
				return {
					label: item?.name,
					value: item?.id,
					onClick: () => onClick(item?.id),
				}
		  })
		: null
}

export const getBusinessTemplate = (
	business,
	onClick = (business_id) => business_id
) => {
	return !!business
		? business?.map((item, i) => {
				return {
					label: item?.template,
					value: item?.id,
					onClick: () => onClick(item?.id),
				}
		  })
		: null
}

export const getIfCanSubmit = ({ files, businessId }) => {
	const hasValidFile = _array.hasAtLeast(1, files)
	const hasValidBusiness = !!businessId

	return hasValidFile && hasValidBusiness
}
