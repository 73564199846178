import SidebarErrorUpload from '../SidebarErrorUpload'
import './History.scss'
import useHistory from './hooks'
import { If, EmptyState, Table, Pagination } from '@redsales/ds'
import classnames from 'classnames'

const History = ({ refetch, setRefetch, filter }) => {
	const {
		files,
		header,
		refresh,
		onClose,
		openModal,
		pagination,
		sidebarData,
		isModalVisible,
	} = useHistory({
		refetch,
		setRefetch,
		filter,
	})

	const isTableVisible = files?.length > 0

	return (
		<div className="upload-history">
			<If
				condition={isTableVisible}
				renderIf={
					<>
						<div className="upload-history__table">
							<h2 className="upload-history__title">Histórico</h2>
							<Table.Body headerItems={header?.info} header={header?.data}>
								{files?.map((item, index) => {
									return (
										<Table.ListItem
											onClick={() => openModal(item)}
											key={`${item?.file_name}-${index}`}
											className={classnames({
												'upload-history__error-line': !!item?.errors,
											})}
										>
											<Table.Row contentForRow={item} />
										</Table.ListItem>
									)
								})}
							</Table.Body>
						</div>
						<Pagination
							currentPage={pagination?.page}
							length={pagination?.total_pages}
							onChange={refresh}
						/>
					</>
				}
				renderElse={
					<EmptyState
						title="Nenhum histórico de upload no momento"
						description="Clique no botão ”Fazer upload” para começar.<br/>O histórico dos seus uploads aparecerão aqui."
					/>
				}
			/>

			<SidebarErrorUpload
				onClose={onClose}
				isVisible={isModalVisible}
				data={sidebarData}
			/>
		</div>
	)
}
export default History
