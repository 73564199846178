/* eslint-disable react-hooks/rules-of-hooks */
import { _browser } from "@redsales/ds/utils"

export const useParameter = (parameter) => {
	if (_browser.isClient()) {
		const queryString = decodeURIComponent(window.location.search)
		const params = new URLSearchParams(queryString)

		return params.get(parameter) || ""
	}

	return null
}

export const useParameters = (...args) => {
	const params = args.flat()

	return params.reduce((list, name) => {
		return {
			...list,
			[name]: useParameter(name),
		}
	}, {})
}
