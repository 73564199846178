const { useForm } = require('react-hook-form')

export const formValues = {
	'has-file': '',
	'file-name': '',
	'file-size': '',
	'file-type': '',
	'base-type': '',
	'product-type': '',
	'file-last-modified': '',
}

const useUploadForm = () => {
	const {
		control,
		setValue,
		getValues,
		handleSubmit,
		watch,
		formState: { errors, isValid },
		...restOfFormMethods
	} = useForm({
		defaultValues: formValues,
		mode: 'onChange',
	})

	return {
		watch,
		errors,
		control,
		isValid,
		setValue,
		getValues,
		handleSubmit,
		...restOfFormMethods,
	}
}

export default useUploadForm
