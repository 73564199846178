export const UPLOAD_DICTIONARY = {
	RECEIVE_FILE: "RECEIVE_FILE",
	ERROR: "ERROR",
}

export const MODAL_STATE = {
	HIDDEN: "HIDDEN",
	VISIBLE: "VISIBLE",
}

export const HIDDEN_MODAL_INFO = { state: MODAL_STATE.HIDDEN, data: {} }
